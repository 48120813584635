import { Data } from '../state';
import {
  KitasData,
  GeoDistricts,
  KitaImagesData,
  ChildImageSharpFluid
} from '../@types/types';
import _ from 'lodash';

/**
 * sort opening times
 */
const sortOpeningTimes = (a: number, b: number) => a - b;

/**
 * reduce opening times
 */
const reduceOpeningTimes = (
  result,
  arg: [string, null | { from: number; to: number }]
): {
  from: number[];
  to: number[];
  badTimesFound: boolean;
} => {
  const { from, to } = arg[1] || { from: -1, to: -1 };
  if (from <= 0 || to <= 0) result.badTimesFound = true;

  if (from > 0) result.from.push(from);

  if (to > 0) result.to.push(to);

  return result;
};

export default {
  /**
   * processes the passed in graphql raw data
   */
  process(data): Data {
    const result: Data = {
      kitas: [],

      kitaImages: [],

      defaultKitaImage: null,

      geoDistricts: {
        features: []
      },

      languages: [],

      openingTimesFrom: [],

      openingTimesTo: [],

      districts: [],

      index: {
        kitaIds: {},

        kitaImageIds: {}
      }
    };

    const kitasData = data.kitas as KitasData;
    kitasData.edges.reduce((acc, { node }) => {
      const reducedOpeningTimes = Object.entries(
        node.openingTimes
      ).reduce(reduceOpeningTimes, { from: [], to: [], badTimesFound: false });

      // push in the kita
      const index = acc.kitas.push({
        id: node.id,

        name: node.name,

        slug: node.slug,

        email: node.email,

        phone: node.phone,

        website: node.website,

        pedagogicalPoints: node.pedagogicalPoints,

        address: node.address,

        location: node.location,

        openingTimes: node.openingTimes,

        minOpeningTimeFrom:
          reducedOpeningTimes.from.length === 0
            ? null
            : _.min(reducedOpeningTimes.from),

        maxOpeningTimeTo:
          reducedOpeningTimes.to.length === 0
            ? null
            : _.max(reducedOpeningTimes.to),

        languages: node.languages,

        errorFlags: {
          languagesNotKnown: node.languages.length === 0,
          openingTimesNotKnown: reducedOpeningTimes.badTimesFound
        },

        selected: false,

        matrix: {
          distance: -1,
          travelTime: -1
        }
      });

      // push in the filtered languages
      acc.languages.push(...node.languages);

      // push in the reduced opening hours
      acc.openingTimesFrom.push(...reducedOpeningTimes.from);
      acc.openingTimesTo.push(...reducedOpeningTimes.to);

      // index kita
      acc.index.kitaIds[node.id] = index - 1;

      return acc;
    }, result);

    const geoDistricts = data.districts as GeoDistricts;

    result.geoDistricts = geoDistricts;

    result.districts = geoDistricts.features.map(feature => {
      return feature.properties.spatial_alias;
    });

    result.languages = _.uniq(result.languages).sort();

    result.openingTimesFrom = _.uniq(result.openingTimesFrom).sort(
      sortOpeningTimes
    );
    result.openingTimesTo = _.uniq(result.openingTimesTo).sort(
      sortOpeningTimes
    );

    const kitaImages = data.kitaImages as KitaImagesData;
    kitaImages.edges.reduce((acc, { node }) => {
      const index = acc.kitaImages.push(node.childImageSharp);
      acc.index.kitaImageIds[node.id] = index;

      return acc;
    }, result);

    result.defaultKitaImage = data.defaultKitaImage
      .childImageSharp as ChildImageSharpFluid;
    return result;
  }
};
