import React from 'react';
import SideMenuBox from '../../Utils/SideMenuBox';
import styled from 'styled-components';
import { Kita } from '../../../state';
import ActiveKita from '../../../containers/Map/ActiveKita';
import { ROUTES } from '../../../constants';
import KitaSearch from './KitaSearch';
import TransitionRouter from '../../TransitionRouter';

interface Props {
    kitas: Kita[];
    isVisible: boolean;
    kitaDetailsMatch: { path:string, uri: string} | null;
    setVisibilty(to: boolean);
    toggleVisibility();
}

const StyledTransitionRouter = styled(TransitionRouter)`
    height: 100%;
    overflow-y: auto;
    position: relative;
`;

const Menu = ({ isVisible, toggleVisibility, kitas }: Props) => {
    return (
        <SideMenuBox isVisible={isVisible} visibilityToggler={toggleVisibility} showIndicator={true}>
            <StyledTransitionRouter>
                <KitaSearch default={true} kitas={kitas} toggleVisibility={toggleVisibility} />
                <ActiveKita path={ROUTES.kitaDetails} toggleVisibility={toggleVisibility} />
            </StyledTransitionRouter>
        </SideMenuBox>
    );
};

export default Menu;