import React from 'react';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Flex, SuccessSolidButton } from '../../../utils/components';
import Select from 'react-select';
import { Kita, MatrixLoadState } from '../../../state';
import { SelectOptions, SelectOption } from '../../../@types/types';
import BrandHeader from '../../Utils/BrandHeader';

const Container = styled.div``;

const Form = styled.form`
  padding: 15px;
  margin: 0;
`;

const FormGroup = styled.div`
  & + div {
    margin-top: 15px;
  }
`;

const Label = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding-left: 8px;
`;

const Separator = styled.span`
  margin: 0 5px;
`;

const customStyles = {
  menu: () => {
    return {
      top: 'auto',
      bottom: '100%'
    };
  },

  menuList: () => {
    return {
      maxHeight: '200px',
      overflowY: 'auto',
      borderBottom: '2px solid #cecded',
      borderLeft: '2px solid #cecded'
    };
  }
};

export interface Props {
  districts: SelectOptions;
  languages: SelectOptions;
  openingTimesFrom: SelectOptions;
  openingTimesTo: SelectOptions;
  travelTimes: SelectOptions;
  travelDistances: SelectOptions;

  selectedLanguage: SelectOption;
  selectedDistricts: SelectOption;
  selectedOpeningTimeFrom: SelectOption;
  selectedOpeningTimeTo: SelectOption;
  selectedTravelTime: SelectOption;
  selectedTravelDistance: SelectOption;

  kitas: Kita[];

  className?: string;
  isVisible: boolean;
  matrixLoadState: MatrixLoadState;

  onDistrictsChange();
  onLanguageChange();
  onOpeningTimesFromChange();
  onOpeningTimesToChange();
  onTravelTimeChange();
  onTravelDistanceChange();

  toggleVisibility();
  triggerSelectAllFilteredKitas(ids: string[]);
  selectAllFilteredKitas();
}

export default ({
  kitas,
  districts,
  languages,
  openingTimesFrom,
  openingTimesTo,
  travelTimes,
  travelDistances,
  selectedLanguage,
  selectedDistricts,
  selectedOpeningTimeFrom,
  selectedOpeningTimeTo,
  selectedTravelTime,
  selectedTravelDistance,
  onDistrictsChange,
  onLanguageChange,
  onOpeningTimesFromChange,
  onOpeningTimesToChange,
  onTravelTimeChange,
  onTravelDistanceChange,
  className,
  matrixLoadState,
  selectAllFilteredKitas,
  toggleVisibility
}: Props) => {
  const isLoading = matrixLoadState === 'loading';
  const isDisabled =
    matrixLoadState !== 'loading' && matrixLoadState !== 'loaded';

  return (
    <Container className={className}>
      <BrandHeader
        text={'Find Kitas'}
        count={`(${kitas.length} results)`}
        onMenuClose={toggleVisibility}
      />
      <Form>
        {/** output districts */}
        <FormGroup>
          <Label htmlFor={'districts'}>
            Show only Kitas in these districts
          </Label>
          <Select
            isMulti={true}
            name={'districts'}
            options={districts}
            closeMenuOnSelect={true}
            styles={customStyles}
            onChange={onDistrictsChange}
            defaultValue={selectedDistricts}
          />
        </FormGroup>

        {/** output languages */}
        <FormGroup>
          <Label htmlFor={'languages'}>with the following language</Label>
          <Select
            name={'language'}
            options={languages}
            isSearchable={true}
            closeMenuOnSelect={true}
            styles={customStyles}
            onChange={onLanguageChange}
            defaultValue={selectedLanguage}
          />
        </FormGroup>

        {/** output opening times */}
        <FormGroup>
          <Flex>
            <div className={'flex'}>
              <Label htmlFor={'opening-times-from'}>open from</Label>
              <Select
                name={'opening-times-from'}
                options={openingTimesFrom}
                className={'flex'}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={customStyles}
                onChange={onOpeningTimesFromChange}
                defaultValue={selectedOpeningTimeFrom}
              />
            </div>
            <Separator />
            <div className={'flex'}>
              <Label htmlFor={'opening-times-to'}>until</Label>
              <Select
                name={'opening-times-to'}
                options={openingTimesTo}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={customStyles}
                onChange={onOpeningTimesToChange}
                defaultValue={selectedOpeningTimeTo}
              />
            </div>
          </Flex>
        </FormGroup>

        {/** output travel times and distances */}
        <FormGroup>
          <Flex>
            <div className={'flex'}>
              <Label htmlFor={'travel-times'}>with commute time</Label>
              <Select
                name={'travel-times'}
                options={travelTimes}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={customStyles}
                onChange={onTravelTimeChange}
                defaultValue={selectedTravelTime}
                isLoading={isLoading}
                isDisabled={isDisabled}
              />
            </div>
            <Separator />
            <div className={'flex'}>
              <Label htmlFor={'travel-distances'}>within distance</Label>
              <Select
                name={'travel-distances'}
                options={travelDistances}
                isSearchable={true}
                closeMenuOnSelect={true}
                styles={customStyles}
                onChange={onTravelDistanceChange}
                defaultValue={selectedTravelDistance}
                isLoading={isLoading}
                isDisabled={isDisabled}
              />
            </div>
          </Flex>
        </FormGroup>
      </Form>
      <Box px={3} py={2}>
        <SuccessSolidButton width={1} onClick={selectAllFilteredKitas}>
          Select All Filtered
        </SuccessSolidButton>
      </Box>
    </Container>
  );
};
