import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import SelectedKitas, { Props } from '../../../../components/Map/Menu/SelectedKitas';
import { createSelector } from 'reselect';
import { AppState } from '../../../../state';
import { deselectAllSelectedKitas, toggleKitaSelection } from '../../../../actions';
import { formatTravelDistance, formatTravelTime, gotoKita } from '../../../../lib/Utils';

const importSpreadsheet = async () => {
    const { default: Spreadsheet } = await import('../../../../lib/Spreadsheet');
    return Spreadsheet;
};

/**
 * pick selected kitas
 */
const pickSelectedKitas = createSelector(
    (state: AppState) => state.data.kitas,

    (kitas) => kitas.filter(kita => kita.selected)
);

export default compose(
    connect(
        (state: AppState) => {
            return {
                selectedKitas: pickSelectedKitas(state),
            };
        },

        (dispatch) => {
            return {

                triggerDeselectAllSelectedKitas(selectedkitasIds: string[]) {
                    dispatch(
                        deselectAllSelectedKitas(selectedkitasIds)
                    );
                },

                deselectKita(e) {
                    const dataset = e.currentTarget.parentNode.dataset;
                    dispatch(
                        toggleKitaSelection(dataset.id)
                    );
                },

                activateKita(e) {
                    const dataset = e.currentTarget.parentNode.dataset;
                    gotoKita(dataset.id, dataset.slug);
                }
            };
        }
    ),

    withHandlers({

        deselectAllSelectedKitas: (props: Props) => e => {
            const ids = props.selectedKitas.map(kita => kita.id);
            props.triggerDeselectAllSelectedKitas(ids);
        },

        generateSpreadsheet: (props: Props) => async (e) => {
            const Spreadsheet = await importSpreadsheet();
            const spreadsheet = Spreadsheet.create('kitaExperts', [
                { key: 'name', header: 'Name' },
                { key: 'email', header: 'Contact email' },
                { key: 'tel', header: 'Telephone number' },
                { key: 'website', header: 'Website' },
                { key: 'address', header: 'Address' },
                { key: 'distance', header: 'Distance to Kita' },
                { key: 'travelTime', header: 'Travel Time to Kita' },
                { key: 'focus', header: 'Focus' },
                { key: 'applicationSent', header: 'Application sent' },
                { key: 'appliedVia', header: 'Applied Via' },
                { key: 'reply', header: 'Reply' },
                { key: 'applicationForm', header: 'Application Form' }
            ]);

            props.selectedKitas.forEach(kita => {
                spreadsheet.addRow({
                    name: kita.name,
                    email: kita.email,
                    tel: kita.phone,
                    website: kita.website,
                    address: `${kita.address.street}, ${kita.address.district}`,
                    distance: formatTravelDistance(kita.matrix.distance).label,
                    travelTime: formatTravelTime(kita.matrix.travelTime).label,
                    focus: kita.pedagogicalPoints,
                    applicationSent: '',
                    appliedVia: '',
                    reply: '',
                    applicationForm: ''
                });
            });

            spreadsheet.generate();
        }
    })

)(SelectedKitas);