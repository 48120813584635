import React from 'react';
import styled from 'styled-components';
import { Kita } from '../../../state';
import SelectedKitas from '../../../containers/Map/Menu/SelectedKitas';
import Filter from '../../../containers/Map/Menu/Filter';

interface Props {
    default?: boolean;
    kitas: Kita[];
    toggleVisibility();
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
`;

const KitaSearch = ({ kitas, toggleVisibility }: Props) => (
    <Container>
        <Filter kitas={kitas} toggleVisibility={toggleVisibility} key='kita-search-filter' />
        <SelectedKitas key='kita-search-selected-kitas' />
    </Container>
);

export default KitaSearch;
