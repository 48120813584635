import React from 'react';
import { Router, Location } from '@reach/router';
import posed, { PoseGroup } from 'react-pose';
import styled, { css } from 'styled-components';

const transition = {
    type: 'tween',
    duration: 100,
};

const RouteContainer = posed.div({
    enter: {
        beforeChildren: true,
        opacity: 1,
        transition,
    },
    exit: {
        beforeChildren: true,
        opacity: 0,
        transition,
    }
});

interface Props {
    children: React.ReactNode;
}

const fullHeight = css`
    height: 100%
`;

const StyledRouteContainer = styled(RouteContainer)`
    ${fullHeight};
`;

const StyledRouter = styled(Router)`
    ${fullHeight};
`;


const TransitionRouter = ({ children }: Props) => (
    <Location>
        {({ location }) => (
            <PoseGroup>
                <StyledRouteContainer key={location.key}>
                    <StyledRouter location={location}>
                        {children}
                    </StyledRouter>
                </StyledRouteContainer>
            </PoseGroup>
        )}
    </Location>
);

export default TransitionRouter;