import * as React from 'react';
import { Map, TileLayer } from 'react-leaflet';
import {
  divIcon,
  point,
  Icon,
  Point,
  LatLngBounds,
  Map as LeafletMap,
  geoJSON as GeoJSON
} from 'leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import Marker from '../../containers/Map/Marker';
import { GeoDistricts } from '../../@types/types';
import { Kita, MatrixLoadState } from '../../state';
import {
  INITIAL_MAP_ZOOM,
  REQUEST_LOCATION_AFTER,
  MAX_CLUSTERING_ZOOM
} from '../../constants';
import styled from 'styled-components';
import { Box } from 'rebass';
import GeoLocator from '../../lib/GeoLocator';
import Menu from '../../containers/Map/Menu';
import theme from '../../utils/theme';

import 'leaflet.markercluster/dist/MarkerCluster.css';

const MapContainer = styled(Box)`
  position: relative;
  height: calc(100vh - 80px);
  width: 100%;
`;

const StyledMap = styled(Map)`
  height: 100%;
  z-index: 1;
`;

const getIcon = ({
  childImageSharp: {
    fluid: { src }
  }
}) => {
  return new Icon({
    iconUrl: src,
    iconRetinaUrl: src,
    iconSize: new Point(30, 30)
  });
};

const createClusterCustomIcon = cluster => {
  const count = cluster.getChildCount();
  return divIcon({
    html: `
            <div>
                <h4 class="kita-count">${count}</h4>
            </div>
        `,

    className: 'marker-cluster-custom',

    iconSize: point(40, 40, true)
  });
};

interface Props {
  geoDistricts: GeoDistricts;

  bounds: LatLngBounds;

  kitas: Kita[];

  className?: string;

  shouldRender?: boolean;

  defaultMarker: any;

  activeMarker: any;

  matrixLoaded: boolean;

  setMatrixLoadState(state: MatrixLoadState);

  loadMatrix(position: Position, kitas: Kita[]);
}

export default class extends React.Component<Props> {
  mapRef = React.createRef<LeafletMap>();

  geoJSON: null | GeoJSON = null;

  geoDistricts: GeoDistricts | null = null;

  geoLocator: GeoLocator = new GeoLocator();

  updateGeoDistricts() {
    if (this.geoDistricts !== this.props.geoDistricts) {
      this.geoDistricts = this.props.geoDistricts;

      if (!this.geoDistricts) return;

      if (this.geoJSON !== null) {
        this.geoJSON.remove();
      }

      this.geoJSON = GeoJSON(this.geoDistricts, {
        style: {
          fillColor: theme.colors.brand,
          color: theme.colors.orange,
          weight: 2,
          fillOpacity: 0.3
        }
      }).addTo(this.mapRef.current.leafletElement);
    }
  }

  locateUser() {
    const { matrixLoaded, loadMatrix, kitas, setMatrixLoadState } = this.props;
    if (!matrixLoaded && this.geoLocator.isAvailable()) {
      setTimeout(() => {
        setMatrixLoadState('requesting');
        this.geoLocator
          .detectPosition(true)
          .then(position => {
            setMatrixLoadState('loading');
            loadMatrix(position, kitas);
          })
          .catch(() => {
            setMatrixLoadState('denied');
          });
      }, REQUEST_LOCATION_AFTER);
    }
  }

  componentDidMount() {
    this.updateGeoDistricts();
    this.locateUser();
  }

  componentDidUpdate() {
    this.updateGeoDistricts();
  }

  render() {
    const {
      bounds,
      kitas,
      className,
      defaultMarker,
      activeMarker
    } = this.props;
    const icons = {
      default: getIcon(defaultMarker),
      active: getIcon(activeMarker)
    };
    return (
      <MapContainer>
        <StyledMap
          maxZoom={20}
          center={bounds.getCenter()}
          className={className}
          zoom={INITIAL_MAP_ZOOM}
          ref={this.mapRef}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          />

          <MarkerClusterGroup
            iconCreateFunction={createClusterCustomIcon}
            spiderfyOnMaxZoom={false}
            disableClusteringAtZoom={MAX_CLUSTERING_ZOOM}
            polygonOptions={{
              fillColor: theme.colors.brand,
              color: theme.colors.orange,
              weight: 2,
              fillOpacity: 0.3
            }}
          >
            {kitas.map(kita => {
              return <Marker key={kita.id} kita={kita} icons={icons} />;
            })}
          </MarkerClusterGroup>
        </StyledMap>

        <Menu kitas={kitas} />
      </MapContainer>
    );
  }
}
