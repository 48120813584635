import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

const LoaderContainer = styled.div`
    display: inline-block;
`;

interface Props {
    type?: string;
}

export default ({ type }: Props) => {
    return (
        <LoaderContainer>
            <Loader type={type || 'ThreeDots'} width={20} height={20} color='#999' />
        </LoaderContainer>
    );
};