import { compose, withState, lifecycle, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import ActiveKita, { OwnProps } from '../../components/Map/ActiveKita';
import { toggleKitaSelection } from '../../actions';
import { createSelector } from 'reselect';
import { AppState } from '../../state';
import { ChildImageSharpFluid } from '../../@types/types';
import { leaveKita, gotoKita } from '../../lib/Utils';
import { SIDE_MENU_TRANSITION_DURATION } from '../../constants';

const selectActiveKitaImage = createSelector(

    (state: AppState, kitaId: string) => kitaId,

    (state: AppState) => state.data.index.kitaImageIds,

    (state: AppState) => state.data.kitaImages,

    (state: AppState) => state.data.defaultKitaImage,

    (activeKita, idsIndex, kitaImages, defaultKitaImage) => {
        const index = idsIndex[activeKita];

        return typeof index !== 'undefined' ? kitaImages[index] : defaultKitaImage;
    }
);

const selectActiveKita = createSelector(

    (state: AppState, kitaId: string) => kitaId,

    (state: AppState) => state.data.index.kitaIds,

    (state: AppState) => state.data.kitas,

    (activeKita, idsIndex, kitas) => {
        const index = idsIndex[activeKita];
        return typeof index !== 'undefined' ? kitas[index] : null;
    }
);

export default compose(

    connect(
        // map state to props
        (state: AppState, ownProps: OwnProps) => {
            return {
                kita: selectActiveKita(state, ownProps.kitaId),

                kitaImage: selectActiveKitaImage(state, ownProps.kitaId),

                matrixLoadState: state.matrixBounds.loadState
            };
        },

        (dispatch) => {
            return {
                triggerToggleKitaSelection(kitaId) {
                    dispatch(
                        toggleKitaSelection(kitaId)
                    );
                },
            };
        }
    ),

    withHandlers({

        gotoKita: props => e => {
            gotoKita(props.kita.id, props.kita.slug);
        },

        toggleKitaSelection: props => e => {
            props.triggerToggleKitaSelection(props.kita.id);
        }
    }),
)(ActiveKita);