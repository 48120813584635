import React from 'react';
import styled, { css } from 'styled-components';
import * as FaTrashAlt from 'styled-icons/fa-regular/TrashAlt';
import * as FaFileDownload from 'styled-icons/fa-solid/FileDownload';
import * as FaAngleLeft from 'styled-icons/fa-solid/AngleLeft';
import * as FaAngleRight from 'styled-icons/fa-solid/AngleRight';
import * as FaStar from 'styled-icons/boxicons-solid/Star';
import * as FaSearch from 'styled-icons/fa-solid/Search';
import * as FaCheck from 'styled-icons/fa-solid/Check';
import * as FaTimes from 'styled-icons/fa-solid/Times';
import * as FaMapMarkerAlt from 'styled-icons/fa-solid/MapMarkerAlt';
import * as FaTaxi from 'styled-icons/fa-solid/Taxi';
import * as FaArrowLeft from 'styled-icons/fa-solid/ArrowLeft';

const iconStyle = css`
    ${props => props.color && `color: ${props.color}`};
    font-weight: 400;
    display: inline-block;
    margin-right: ${props => props.giveSpace ? '7px' : 0};
    font-size: 0.9em;
    height: 20px;
`;

const createStyledIcon = (icon: React.ComponentType<any>, attrs: {}) =>
    styled(icon).attrs(attrs)`
    ${iconStyle};
`;

const TrashAlt = createStyledIcon(FaTrashAlt.TrashAlt, FaTrashAlt.TrashAltDimensions);
const Download = createStyledIcon(FaFileDownload.FileDownload, FaFileDownload.FileDownloadDimensions);
const AngleLeft = createStyledIcon(FaAngleLeft.AngleLeft, FaAngleLeft.AngleLeftDimensions);
const AngleRight = createStyledIcon(FaAngleRight.AngleRight, FaAngleRight.AngleRightDimensions);
const Star = createStyledIcon(FaStar.Star, FaStar.StarDimensions);
const Search = createStyledIcon(FaSearch.Search, FaSearch.SearchDimensions);
const Check = createStyledIcon(FaCheck.Check, FaCheck.CheckDimensions);
const Times = createStyledIcon(FaTimes.Times, FaTimes.TimesDimensions);
const MapMarkerAlt = createStyledIcon(FaMapMarkerAlt.MapMarkerAlt, FaMapMarkerAlt.MapMarkerAltDimensions);
const Taxi = createStyledIcon(FaTaxi.Taxi, FaTaxi.TaxiDimensions);
const ArrowLeft = createStyledIcon(FaArrowLeft.ArrowLeft, FaArrowLeft.ArrowLeftDimensions);

const SUPPORTED_ICONS = {
    'trash-alt': TrashAlt,
    download: Download,
    'angle-left': AngleLeft,
    'angle-right': AngleRight,
    star: Star,
    search: Search,
    check: Check,
    times: Times,
    'map-marker-alt': MapMarkerAlt,
    taxi: Taxi,
    'arrow-left': ArrowLeft
};

interface IconProps {
    icon: string;
}

const Error = ({ icon }: IconProps) => <span>{icon}?</span>;

interface Props {
    className?: string;
    giveSpace?: boolean;
}

const Icon = (props: Props & IconProps) => {
    const { icon } = props;

    const FoundIcon = SUPPORTED_ICONS[icon] || Error;
    return (
        <FoundIcon {...props} />
    );
};

export default Icon;

export const IconButton = styled(Icon)`
    cursor: pointer;

    margin-right: ${props => props.giveSpace ? '15px' : 0};

    &[disabled] {
        opacity: 0.3;
    }
`;