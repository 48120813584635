import { compose } from 'recompose';
import Map from '../../components/Map';
import { connect } from 'react-redux';
import { AppState, Kita, MatrixLoadState } from '../../state';
import { createSelector } from 'reselect';
import {
    DEFAULT_LANGUAGE, BERLIN_LAT_LNG_BOUND, DEFAULT_OPENING_TIME_FROM,
    DEFAULT_OPENING_TIME_TO,
    DEFAULT_TRAVEL_TIME,
    DEFAULT_TRAVEL_DISTANCE
} from '../../constants';
import { LatLngBounds } from 'leaflet';
import MatrixLoader from '../../lib/MatrixLoader';
import { loadMatrix, setMatrixLoadState } from '../../actions';

/**
 * select geo districts
 */
const selectGeoDistricts = createSelector(

    (state: AppState) => state.data.geoDistricts,

    (state: AppState) => state.userChoice.selectedDistricts,

    (geoDistricts, selectedDistricts) => {
        if (selectedDistricts.length === 0)
            return geoDistricts;

        else
            return {
                ...geoDistricts,
                features: geoDistricts.features.filter(feature => {
                    return selectedDistricts.includes(feature.properties.spatial_alias);
                })
            };
    }
);

/**
 * select kitas
 */
const selectKitas = createSelector(

    (state: AppState) => state.data.kitas,

    (state: AppState) => state.userChoice.selectedDistricts,

    (state: AppState) => state.userChoice.selectedLanguage,

    (state: AppState) => state.userChoice.selectedOpeningTimeFrom,

    (state: AppState) => state.userChoice.selectedOpeningTimeTo,

    (state: AppState) => state.userChoice.selectedTravelTime,

    (state: AppState) => state.userChoice.selectedTravelDistance,

    (state: AppState) => state.matrixBounds.loadState === 'loaded',

    (kitas, selectedDistricts, selectedLanguage, selectedOpeningTimeFrom, selectedOpeningTimeTo,
        selectedTravelTime, selectedTravelDistance, matrixLoaded) => {

        const passDistricts = selectedDistricts.length === 0;
        const passLanguages = selectedLanguage === DEFAULT_LANGUAGE.value;
        const passOpeningTimeFrom = selectedOpeningTimeFrom === DEFAULT_OPENING_TIME_FROM.value;
        const passOpeningTimeTo = selectedOpeningTimeTo === DEFAULT_OPENING_TIME_TO.value;
        const passTravelTimes = !matrixLoaded || selectedTravelTime === DEFAULT_TRAVEL_TIME.value;
        const passTravelDistances = !matrixLoaded || selectedTravelDistance === DEFAULT_TRAVEL_DISTANCE.value;

        const nSelectedOpeningTimeFrom = Number.parseFloat(selectedOpeningTimeFrom);
        const nSelectedOpeningTimeTo = Number.parseFloat(selectedOpeningTimeTo);
        const nSelectedTravelTime = Number.parseFloat(selectedTravelTime);
        const nSelectedTravelDistance = Number.parseFloat(selectedTravelDistance);

        return kitas.filter(kita => {
            return (passDistricts || selectedDistricts.includes(kita.address.district))
                &&
                (passLanguages || kita.languages.includes(selectedLanguage))
                &&
                (passOpeningTimeFrom || (kita.minOpeningTimeFrom && kita.minOpeningTimeFrom <= nSelectedOpeningTimeFrom))
                &&
                (passOpeningTimeTo || (kita.maxOpeningTimeTo && kita.maxOpeningTimeTo >= nSelectedOpeningTimeTo))
                &&
                (passTravelTimes || kita.matrix.travelTime <= nSelectedTravelTime)
                &&
                (passTravelDistances || kita.matrix.distance <= nSelectedTravelDistance);
        });
    }
);

const selectBounds = createSelector(

    selectKitas,

    (kitas) => {
        const locations = kitas.length > 0 ?
            kitas.map(kita => kita.location) : BERLIN_LAT_LNG_BOUND;

        return new LatLngBounds(locations);
    }
);

export default compose(

    connect(
        // map state to props
        (state: AppState) => {
            return {

                kitas: selectKitas(state),

                geoDistricts: selectGeoDistricts(state),

                bounds: selectBounds(state),

                matrixLoaded: state.matrixBounds.loadState === 'loaded',
            };
        },

        // map dispatch to props
        (dispatch) => {
            return {

                setMatrixLoadState(state: MatrixLoadState) {
                    dispatch(
                        setMatrixLoadState(state)
                    );
                },

                async loadMatrix(position: Position, kitas: Kita[]) {
                    try {
                        const kitasMatrixes = await MatrixLoader.load(position, kitas);
                        dispatch(
                            loadMatrix(kitasMatrixes)
                        );
                    }
                    catch (ex) {
                        dispatch(
                            setMatrixLoadState('loadError')
                        );
                    }
                },
            };
        }
    ),
)(Map);