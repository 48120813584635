import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import { AppState } from '../../../state';
import { ROUTES } from '../../../constants';
import Menu from '../../../components/Map/Menu';
import { setMapMenuVisibility } from '../../../actions';
import matchFor from '../../../components/Utils/MatchFor';

export default compose(
    matchFor(ROUTES.kitaDetails),
    connect(
        // map state to props
        (state: AppState) => {
            return {
                isVisible: state.componentVisibilities.mapMenu
            };
        },
        {
            setVisibility: setMapMenuVisibility
        }
    ),
    withHandlers({
        toggleVisibility: ({ isVisible, match, navigate, setVisibility }) => () => {
            const isClosingActiveKita = isVisible && match !== null;
            if (isClosingActiveKita) {
                navigate('/search');
            } else {
                setVisibility(!isVisible);
            }
        }
    }),
)(Menu);