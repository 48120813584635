import { compose, withHandlers } from 'recompose';
import { connect } from 'react-redux';
import Filter, { Props } from '../../../components/Map/Menu/Filter';
import {
    setSelectedDistricts, setSelectedLanguage, setSelectedOpeningTimeFrom,
    setSelectedOpeningTimeTo,
    selectAllFilteredKitas,
    setSelectedTravelTime,
    setSelectedTravelDistance
} from '../../../actions';
import { AppState } from '../../../state';
import { createSelector } from 'reselect';
import { formatOpeningTime, labelItems, formatTravelTime, formatTravelDistance, findOption } from '../../../lib/Utils';
import {
    DEFAULT_OPENING_TIME_TO, DEFAULT_OPENING_TIME_FROM,
    DEFAULT_LANGUAGE,
    DEFAULT_TRAVEL_TIME,
    DEFAULT_TRAVEL_DISTANCE
} from '../../../constants';
import { SelectOptions } from '../../../@types/types';

/**
 * label travel distances
 */
const labelTravelDistances = createSelector(
    (state: AppState) => state.matrixBounds.loadState === 'loaded',

    (state: AppState) => state.matrixBounds.minDistance,

    (state: AppState) => state.matrixBounds.maxDistance,

    (matrixLoaded, minTravelDistance, maxTravelDistance) => {
        const result: SelectOptions = [DEFAULT_TRAVEL_DISTANCE];

        if (matrixLoaded) {
            const interval = Math.floor((maxTravelDistance - minTravelDistance) / 10);
            let start = minTravelDistance;
            while (start <= maxTravelDistance) {
                result.push(
                    formatTravelDistance(start)
                );
                start += interval;
            }
        }

        return result;
    }
);

/**
 * label travel times
 */
const labelTravelTimes = createSelector(
    (state: AppState) => state.matrixBounds.loadState === 'loaded',

    (state: AppState) => state.matrixBounds.minTravelTime,

    (state: AppState) => state.matrixBounds.maxTravelTime,

    (matrixLoaded, minTravelTime, maxTravelTime) => {
        const result: SelectOptions = [DEFAULT_TRAVEL_TIME];

        if (matrixLoaded) {
            const interval = Math.floor((maxTravelTime - minTravelTime) / 10);
            let start = minTravelTime;
            while (start <= maxTravelTime) {
                result.push(
                    formatTravelTime(start)
                );
                start += interval;
            }
        }

        return result;
    }
);

/**
 * labels opening times to
 */
const labelOpeningTimesTo = createSelector(
    (state: AppState) => state.data.openingTimesTo,

    (openingTimesTo) => {
        const result = openingTimesTo.map(formatOpeningTime);
        result.unshift(DEFAULT_OPENING_TIME_TO);

        return result;
    }
);

/**
 * label opening times from
 */
const labelOpeningTimesFrom = createSelector(

    (state: AppState) => state.data.openingTimesFrom,

    (openingTimesFrom) => {
        const result = openingTimesFrom.map(formatOpeningTime);
        result.unshift(DEFAULT_OPENING_TIME_FROM);

        return result;
    }
);

/**
 * label districts
 */
const labelDistricts = createSelector(
    (state: AppState) => state.data.districts,

    (districts) => {
        const result = labelItems(districts);
        return result;
    }
);

/**
 * get the languages labeled
 */
const labelLanguages = createSelector(

    (state: AppState) => state.data.languages,

    (languages) => {
        const result = labelItems(languages);
        result.unshift(DEFAULT_LANGUAGE);

        return result;
    }
);

/**
 * picks out the selected travel distance option
 */
const pickSelectedTravelDistance = createSelector(
    labelTravelDistances,

    (state: AppState) => state.userChoice.selectedTravelDistance,

    (travelDistances, selectedTravelDistance) => {
        return findOption(travelDistances, selectedTravelDistance);
    }
);

/**
 * picks out the selected travel time option
 */
const pickSelectedTravelTime = createSelector(
    labelTravelTimes,

    (state: AppState) => state.userChoice.selectedTravelTime,

    (travelTimes, selectedTravelTime) => {
        return findOption(travelTimes, selectedTravelTime);
    }
);

/**
 * picks out the selected opening time to.
 */
const pickSelectedOpeningTimeTo = createSelector(

    labelOpeningTimesTo,

    (state: AppState) => state.userChoice.selectedOpeningTimeTo,

    (openingTimesTo, selectedOpeningTimeTo) => {
        return findOption(openingTimesTo, selectedOpeningTimeTo);
    }
);

/**
 * picks out the selected opening time from
 */
const pickSelectedOpeningTimeFrom = createSelector(

    labelOpeningTimesFrom,

    (state: AppState) => state.userChoice.selectedOpeningTimeFrom,

    (openingTimesFrom, selectedOpeningTimeFrom) => {
        return findOption(openingTimesFrom, selectedOpeningTimeFrom);
    }
);

/**
 * picks out the selected districts.
 */
const pickSelectedDistricts = createSelector(

    labelDistricts,

    (state: AppState) => state.userChoice.selectedDistricts,

    (districts, selectedDistricts) => {
        return districts.filter(district => selectedDistricts.includes(district.value));
    }
);

/**
 * picks out the selected language.
 */
const pickSelectedLanguage = createSelector(

    labelLanguages,

    (state: AppState) => state.userChoice.selectedLanguage,

    (languages, selectedLanguage) => {
        return findOption(languages, selectedLanguage);
    }
);

export default compose(
    connect(
        // map state to props
        (state: AppState) => {
            return {
                matrixLoadState: state.matrixBounds.loadState,

                languages: labelLanguages(state),

                districts: labelDistricts(state),

                openingTimesFrom: labelOpeningTimesFrom(state),

                openingTimesTo: labelOpeningTimesTo(state),

                travelTimes: labelTravelTimes(state),

                travelDistances: labelTravelDistances(state),

                selectedLanguage: pickSelectedLanguage(state),

                selectedDistricts: pickSelectedDistricts(state),

                selectedOpeningTimeFrom: pickSelectedOpeningTimeFrom(state),

                selectedOpeningTimeTo: pickSelectedOpeningTimeTo(state),

                selectedTravelTime: pickSelectedTravelTime(state),

                selectedTravelDistance: pickSelectedTravelDistance(state),
            };
        },

        (dispatch) => {
            return {

                onDistrictsChange(e) {
                    const selectedDistricts = e.map(district => district.value);
                    dispatch(
                        setSelectedDistricts(selectedDistricts)
                    );
                },

                onLanguageChange(e) {
                    dispatch(
                        setSelectedLanguage(e.value)
                    );
                },

                onOpeningTimesFromChange(e) {
                    dispatch(
                        setSelectedOpeningTimeFrom(e.value)
                    );
                },

                onOpeningTimesToChange(e) {
                    dispatch(
                        setSelectedOpeningTimeTo(e.value)
                    );
                },

                onTravelTimeChange(e) {
                    dispatch(
                        setSelectedTravelTime(e.value)
                    );
                },

                onTravelDistanceChange(e) {
                    dispatch(
                        setSelectedTravelDistance(e.value)
                    );
                },

                triggerSelectAllFilteredKitas(kitaIds: string[]) {
                    dispatch(
                        selectAllFilteredKitas(kitaIds)
                    );
                }
            };
        }
    ),

    withHandlers({

        selectAllFilteredKitas: (props: Props) => e => {
            const ids = props.kitas.map(kita => kita.id);
            props.triggerSelectAllFilteredKitas(ids);
        }
    }),
)(Filter);