import React from 'react';
import { graphql } from 'gatsby';

import Search from '../containers/Search';
import { createPage } from '../template';

interface MapProps {
  defaultMarker: any;
  activeMarker: any;
}

export interface Props {
  data: any;
  dataLoaded: boolean;
  loadData(data);
}

let Map = (props: MapProps) => {
  return <div />;
};

class Page extends React.Component<Props> {
  componentDidMount() {
    const { dataLoaded, loadData, data } = this.props;
    if (!dataLoaded) {
      loadData(data);
    }
  }

  shouldComponentUpdate() {
    Map = require('../containers/Map').default;
    return true;
  }

  render() {
    const { data } = this.props;
    return (
      <Map
        defaultMarker={data.defaultMarker}
        activeMarker={data.activeMarker}
      />
    );
  }
}

export default createPage(Search(Page), {
  headerType: 'full',
  showFooter: false
});

export const query = graphql`
  query SearchQuery {
    defaultMarker: file(relativePath: { eq: "bear_inactive.png" }) {
      childImageSharp {
        fluid(maxWidth: 155, maxHeight: 155, toFormat: PNG) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    activeMarker: file(relativePath: { eq: "bear.png" }) {
      childImageSharp {
        fluid(maxWidth: 155, maxHeight: 155, toFormat: PNG) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    kitas: allKitasJson {
      totalCount
      edges {
        node {
          id
          name
          slug
          email
          phone
          website
          address {
            district
            street
          }
          location
          languages
          openingTimes {
            mon {
              from
              to
            }
            tue {
              from
              to
            }
            wed {
              from
              to
            }
            thu {
              from
              to
            }
            fri {
              from
              to
            }
          }
          pedagogicalPoints
        }
      }
    }

    kitaImages: allFile(filter: { relativePath: { regex: "/kita-images/" } }) {
      edges {
        node {
          id: name
          childImageSharp {
            fluid(maxHeight: 200, maxWidth: 400, toFormat: PNG) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    }

    defaultKitaImage: file(relativePath: { eq: "kita.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 200, maxWidth: 400, toFormat: PNG) {
          ...GatsbyImageSharpFluid
          presentationWidth
        }
      }
    }

    districts: districtsJson {
      id
      type
      crs {
        type
      }
      features {
        type
        properties {
          gml_id
          spatial_name
          spatial_alias
          spatial_type
        }
        geometry {
          type
          coordinates
        }
      }
    }

    languages: allKitasJson {
      edges {
        node {
          languages
        }
      }
    }
  }
`;
