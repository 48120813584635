import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';

export default styled.ul`
    margin: 5px 0;
    list-style-type: none;

    > li {
        display: ${props => props.displayStyle === 'inline' ? 'inline-block' : 'flex'};
        align-items: center;
        margin: 0;
    }

    > li + li {
        margin: ${props => props.displayStyle === 'inline' ? '0 0 0 10px' : '7px 0 0 0'};
    }
`;

export const Item = styled.li`
    align-items: center;
`;

const IconContainer = styled.span`
    margin-right: 5px;
`;

export const Label = styled.span`
    flex: 1;
    font-size: 0.9em;
`;

interface Props {
    icon: string;
    className?: string;
}

export const ItemIcon = ({ className, icon }: Props) => {
    return (
        <IconContainer className={className}>
            <Icon icon={icon} />
        </IconContainer>
    );
};