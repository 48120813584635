import React from 'react';
import styled from 'styled-components';
import { Button } from '../../utils/components';
import Icon from './Icon';
import { SIDE_MENU_TRANSITION_DURATION } from '../../constants';

const Container = styled.div`
  position: fixed;
  top: 80px;
  left: 0;
  width: 330px;
  height: calc(100vh - 80px);
  z-index: ${props => props.zIndex || 2};
  transition: ${() =>
    `transform ${SIDE_MENU_TRANSITION_DURATION}ms ease-in-out`};
  transform: translateX(${props => (props.isVisible ? '0%' : '-100%')});
  box-shadow: 1px 4px 8px rgba(0, 0, 0, 0.4);
  background: rgba(255, 255, 255, 0.93);
`;

const CloseButton = styled(Button).attrs({
  bg: 'brand',
  fontSize: 4,
  color: '#000'
})`
  position: absolute;
  left: 100%;
  top: 80px;
  z-index: 1;
  padding: 25px 7px;
  display: ${props =>
    props.showIndicator || props.isVisible ? 'block' : 'none'};
  border-radius: 0;
`;

const Content = styled.div`
  height: 100%;
  overflow-y: auto;
`;

interface Props {
  zIndex?: number;
  autoScrollBar?: boolean;
  isVisible: boolean;
  showIndicator?: boolean;
  children: any;
  visibilityToggler();
}

export default ({
  zIndex,
  isVisible,
  visibilityToggler,
  children,
  showIndicator,
  autoScrollBar
}: Props) => {
  return (
    <Container zIndex={zIndex} isVisible={isVisible}>
      <CloseButton
        onClick={visibilityToggler}
        isVisible={isVisible}
        showIndicator={showIndicator}
      >
        <Icon icon={isVisible ? 'angle-left' : 'angle-right'} />
      </CloseButton>
      <Content>{children}</Content>
    </Container>
  );
};
