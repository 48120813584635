export default class {
  watchId: null | number = null;

  isAvailable() {
    return typeof window !== 'undefined' && 'geolocation' in window.navigator;
  }

  detectPosition(
    watchPosition = true,
    positionOptions: PositionOptions = {
      enableHighAccuracy: true
    }
  ): Promise<Position> {
    return new Promise((resolve, reject) => {
      const success = (position: Position) => resolve(position);
      const failure = (error: PositionError) => reject(error);

      if (watchPosition) {
        this.watchId = window.navigator.geolocation.watchPosition(
          success,
          failure,
          positionOptions
        );
      } else {
        window.navigator.geolocation.getCurrentPosition(
          success,
          failure,
          positionOptions
        );
      }
    });
  }

  stopWatch() {
    if (this.watchId !== null) {
      window.navigator.geolocation.clearWatch(this.watchId);
    }
  }
}
