import { Kita, Matrix } from '../state';
import _ from 'lodash';
import { MATRIX_ENDPOINT_COMMON_PARAMS, MATRIX_ENDPOINT } from '../constants';
import { AxiosStatic } from 'axios';
import { MatrixEntryData, ProcessedMatrixData } from '../@types/types';

let endpointCommonParamsCache: null | string = null;

const importAxios = async () => {
  const { default: axios } = await import('axios');
  return axios;
};

const buildDestinationsQuery = (kitaChunk: Kita[]) => {
  return kitaChunk
    .map((kita, index) => {
      return `destination${index}=${encodeURIComponent(
        kita.location.join(',')
      )}`;
    })
    .join('&');
};

const buildEndpointCommonParams = (position: Position) => {
  if (endpointCommonParamsCache === null) {
    const params = {
      ...MATRIX_ENDPOINT_COMMON_PARAMS,
      start0: `${position.coords.latitude},${position.coords.longitude}`
    };
    endpointCommonParamsCache = Object.entries(params)
      .map(([name, value]) => {
        return name + '=' + encodeURIComponent(value);
      })
      .join('&');
  }
  return endpointCommonParamsCache;
};

const fetchMatrixEntries = (
  axios: AxiosStatic,
  position: Position,
  kitaChunk: Kita[]
): Promise<MatrixEntryData[]> => {
  const destinations = buildDestinationsQuery(kitaChunk);
  const commonQueries = buildEndpointCommonParams(position);

  return axios(`${MATRIX_ENDPOINT}?${commonQueries}&${destinations}`).then(
    response => {
      return response.data.response.matrixEntry;
    }
  );
};

const min = (prev, next) => {
  return prev === 0 || next < prev ? next : prev;
};

const max = (prev, next) => {
  return prev === 0 || next > prev ? next : prev;
};

export default {
  async load(position: Position, kitas: Kita[]): Promise<ProcessedMatrixData> {
    const axios = await importAxios();
    const kitaChunks = _.chunk(kitas, 100) as Kita[][];

    const result: ProcessedMatrixData = {
      minDistance: 0,
      maxDistance: 0,
      minTravelTime: 0,
      maxTravelTime: 0,
      matrix: []
    };
    let startIndex = 0;

    // use parallel loading
    const promises = kitaChunks.map(kitaChunk => {
      return {
        promise: fetchMatrixEntries(axios, position, kitaChunk),
        length: kitaChunk.length
      };
    });

    for (const promise of promises) {
      const matrixEntries = await promise.promise;
      matrixEntries.forEach(entry => {
        const { distance, travelTime } = entry.summary;
        const index = startIndex + entry.destinationIndex;

        result.minDistance = min(result.minDistance, distance);
        result.maxDistance = max(result.maxDistance, distance);

        result.minTravelTime = min(result.minTravelTime, travelTime);
        result.maxTravelTime = max(result.maxTravelTime, travelTime);

        result.matrix[index] = {
          distance: entry.summary.distance,
          travelTime: entry.summary.travelTime
        };
      });
      startIndex += promise.length;
    }

    return result;
  }
};
