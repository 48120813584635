import React from 'react';
import styled from 'styled-components';
import {
  BrandSolidButton,
  Flex,
  BrandPlainButton
} from '../../../../utils/components';
import { Kita } from '../../../../state';
import SelectedKita from './SelectedKita';
import BrandHeader from '../../../Utils/BrandHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-height: 100%;
`;

const StyledSelectedKitas = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
`;

const StyledEmptySelectedKitas = styled.div`
  height: 4.8em;
  line-height: 4.8em;
  font-size: 1.2em;
  padding: 0 15px;
`;

const BorderlessButton = styled(BrandPlainButton)`
  border: none;

  &:hover {
    border: none;
  }
`;

export interface Props {
  selectedKitas: Kita[];

  className?: string;

  generateSpreadsheet();
  triggerDeselectAllSelectedKitas(selectedkitasIds: string[]);
  deselectAllSelectedKitas();
  deselectKita();
  activateKita();
}

export default ({
  className,
  selectedKitas,
  generateSpreadsheet,
  deselectAllSelectedKitas,
  deselectKita,
  activateKita
}: Props) => {
  const length = selectedKitas.length;
  const disabled = length === 0;
  return (
    <Container className={className}>
      <BrandHeader
        icon={'star'}
        text={'Selected Kitas'}
        count={`(${selectedKitas.length})`}
      />
      <Content>
        {length === 0 && (
          <StyledEmptySelectedKitas>No kitas selected</StyledEmptySelectedKitas>
        )}
        {length > 0 && (
          <StyledSelectedKitas>
            {selectedKitas.map(kita => {
              return (
                <SelectedKita
                  key={kita.id}
                  kita={kita}
                  deselectKita={deselectKita}
                  activateKita={activateKita}
                />
              );
            })}
          </StyledSelectedKitas>
        )}
      </Content>
      <Flex flexDirection='column' pt={2}>
        <BrandSolidButton
          mx={3}
          mb={2}
          onClick={generateSpreadsheet}
          disabled={disabled}
        >
          Download Kita Spreadsheet
        </BrandSolidButton>
        <BorderlessButton
          borderColor='transparent'
          mx={3}
          mb={3}
          onClick={deselectAllSelectedKitas}
          disabled={disabled}
        >
          Clear
        </BorderlessButton>
      </Flex>
    </Container>
  );
};
