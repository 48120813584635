import React from 'react';
import styled from 'styled-components';
import { Kita, MatrixLoadState } from '../../state';
import { Box, Text } from 'rebass';
import Img from 'gatsby-image';
import { ChildImageSharpFluid } from '../../@types/types';
import IconizedItems, { Item, Label, ItemIcon } from '../Utils/IconizedItems';
import Loader from '../Utils/Loader';
import Icon from '../Utils/Icon';
import {
  formatTravelDistance,
  formatTravelTime,
  capitalize,
  pairOpeningHours
} from '../../lib/Utils';
import BrandHeader from '../Utils/BrandHeader';
import { BrandPlainButton, BrandSolidButton } from '../../utils/components';

const ImgWrapper = styled(Box)`
  width: 100%;
`;

const ContentWrapper = styled(Box)`
  margin: 15px 10px;
`;

const KitaNotFound = styled(Box).attrs({
  fontSize: 5
})`
  margin: 70px 16px 0 16px;
  text-align: center;
`;

const DetailsList = styled(Box).attrs({
  as: 'ul'
})`
  list-style: none;
  margin-left: 0;
  margin-top: 20px;
`;

const DetailsListItem = styled(Box).attrs({
  as: 'li'
})`
  margin-bottom: 10px;
`;

const DetailsListItemLabel = styled(Box).attrs({
  as: 'p'
})`
  color: ${props => props.theme.colors.grey};
  margin-bottom: 0;
  font-size: 16px;
`;

export interface OwnProps {
  kitaId: string;
}

const formatLanguages = (languages: string[]) => {
  return languages
    .map(language => capitalize(language.split('-')[1].trim()))
    .join(', ');
};

export interface Props extends OwnProps {
  kita: Kita | null;

  kitaImage: ChildImageSharpFluid;

  isSelected: boolean;

  matrixLoadState: MatrixLoadState;

  closeContainer();

  gotoKita();

  toggleKitaSelection();

  toggleVisibility();
}

export default function ActiveKita({
  kita,
  kitaImage,
  toggleKitaSelection,
  matrixLoadState,
  toggleVisibility
}: Props) {
  const loading = matrixLoadState === 'loading';
  const loaded = matrixLoadState === 'loaded';

  if (!kita) {
    return <KitaNotFound>Kita Not Found</KitaNotFound>;
  }

  return (
    <div>
      <BrandHeader text={kita.name} onMenuClose={toggleVisibility} />
      <ImgWrapper>
        <Img fluid={kitaImage.fluid} />
      </ImgWrapper>

      <ContentWrapper>
        <Box mb={3}>
          {kita.selected && (
            <BrandPlainButton width={1} onClick={toggleKitaSelection}>
              <Icon icon={'times'} giveSpace={true} /> Deselect
            </BrandPlainButton>
          )}
          {!kita.selected && (
            <BrandSolidButton width={1} onClick={toggleKitaSelection}>
              <Icon icon={'check'} giveSpace={true} /> Select
            </BrandSolidButton>
          )}
        </Box>

        <IconizedItems displayStyle='inline'>
          <Item>
            <ItemIcon icon={'map-marker-alt'} />
            <Label>
              {(loaded && formatTravelDistance(kita.matrix.distance).label) ||
                (loading && <Loader />) ||
                '---'}
            </Label>
          </Item>
          <Item>
            <ItemIcon icon={'taxi'} />
            <Label>
              {(loaded && formatTravelTime(kita.matrix.travelTime).label) ||
                (loading && <Loader />) ||
                '---'}
            </Label>
          </Item>
        </IconizedItems>

        <DetailsList mt={3}>
          <DetailsListItem>
            <DetailsListItemLabel>Address</DetailsListItemLabel>
            <Text>
              {kita.address.street}, {kita.address.district}
            </Text>
          </DetailsListItem>

          <DetailsListItem>
            <DetailsListItemLabel>Email</DetailsListItemLabel>
            <Text>{kita.email}</Text>
          </DetailsListItem>

          <DetailsListItem>
            <DetailsListItemLabel>Phone</DetailsListItemLabel>
            <Text>{kita.phone}</Text>
          </DetailsListItem>

          <DetailsListItem>
            <DetailsListItemLabel>Languages</DetailsListItemLabel>
            {kita.languages.length > 0 && (
              <Text>{formatLanguages(kita.languages)}</Text>
            )}
            {kita.languages.length === 0 && (
              <Text color={'warning'}>no record found</Text>
            )}
          </DetailsListItem>

          <DetailsListItem>
            <DetailsListItemLabel>Opening hours</DetailsListItemLabel>
            {kita.minOpeningTimeFrom && (
              <Text>{pairOpeningHours(kita.openingTimes).join('; ')}</Text>
            )}
            {!kita.minOpeningTimeFrom && (
              <Text color={'warning'}>no record found</Text>
            )}
          </DetailsListItem>

          <DetailsListItem>
            <DetailsListItemLabel>Pedagogical Focus</DetailsListItemLabel>
            <Text>{kita.pedagogicalPoints || '---'}</Text>
          </DetailsListItem>
        </DetailsList>
      </ContentWrapper>
    </div>
  );
}
