
import React from 'react';
import { Location, Match } from '@reach/router';

const matchFor = (path: string) => <TProps extends {}>(Component: React.ComponentType<TProps>) => (props: TProps) => (
    <Location>
        {({ navigate }) => (
            <Match path={path}>
                {({ match }) => <Component {...props} match={match} navigate={navigate} />}
            </Match>
        )}
    </Location>
);

export default matchFor;