import React from 'react';
import { Kita } from '../../../../state';
import Icon from '../../../Utils/Icon';
import styled from 'styled-components';
import theme from '../../../../utils/theme';

const KITA_SIZE = '50px';

const StyledSelectedKita = styled.li`
    display: flex;
    margin: 0;
    align-items: stretch;
    padding-left: 15px;
    position: relative;
    justify-content: space-between;
    height: ${KITA_SIZE};
    transition: background-color 0.2s ease;
    border-bottom: 1px solid rgba(0,0,0,0.05);

    &:hover {
        background-color: #efefef;
    }

    & > .kita-name {
        cursor: pointer;
        flex: 1;
        line-height: ${KITA_SIZE};
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    & > .delete-button {
        width: 45px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: opacity 0.2s, background-color 0.2s, color 0.2s;
        color: #ddd;
        background-color: transparent;
        position: relative;

        &::after {
            content: ' ';
            display: block;
            position: absolute;
            bottom: -1px;
            height: 1px;
            width: 100%;
            background-color: transparent;
            transition: background-color 0.2s;
        }

        &:hover {
            opacity: 0.3;
        }
    }

    &:hover {
        > .delete-button {
                opacity: 1;
                background-color: ${theme.colors.red};
                color: #fff;

                &::after {
                    background-color: ${theme.colors.red};
                }
            }
    }
`;

export interface Props {
    kita: Kita;
    deselectKita();
    activateKita();
}

export default function ({ kita, activateKita, deselectKita }: Props) {
    return (
        <StyledSelectedKita data-id={kita.id} data-slug={kita.slug}>
            <span className={'kita-name'} onClick={activateKita}>{kita.name}</span>
            <span className={'delete-button'} onClick={deselectKita}><Icon icon='times' /></span>
        </StyledSelectedKita>
    );
}