import React from 'react';
import styled from 'styled-components';
import { Text, Flex } from 'rebass';
import Icon from './Icon';
import { media } from '../../utils/theme';

const StyledBrandHeader = styled(Flex).attrs({
  bg: 'brand',
  color: 'black',
  p: '10px 16px',
  fontSize: '16px',
  alignItems: 'center'
})`
  min-height: 53px;
`;

const StyledIcon = styled(Icon).attrs({
  giveSpace: true
})``;

const StyledText = styled(Text)``;

const StyledCount = styled(Text).attrs({
  color: 'grey',
  fontWeight: '400'
})`
  margin-left: 7px;
`;

const CloseButton = styled.span`
  cursor: pointer;
  margin-right: 15px;

  ${media.greaterThan('md')`
        /* display: none; */
    `}
`;

interface Props {
  icon?: string;
  text: string;
  count?: string;
  onMenuClose?: () => void;
}

export default ({ icon, text, count, onMenuClose }: Props) => {
  return (
    <StyledBrandHeader>
      {onMenuClose && (
        <CloseButton onClick={onMenuClose}>
          <Icon icon={'arrow-left'} />
        </CloseButton>
      )}

      {icon && <StyledIcon icon={icon} />}

      <StyledText>{text}</StyledText>

      {count && <StyledCount>{count}</StyledCount>}
    </StyledBrandHeader>
  );
};
