import { connect } from 'react-redux';
import { compose } from 'recompose';
import { loadData } from '../actions';
import { AppState } from '../state';
import _ from 'lodash';
import DataProcessor from '../lib/DataProcessor';

export default function (baseSearchComponent) {
    return compose(

        connect(
            // map state to props
            (state: AppState) => {
                return {
                    dataLoaded: state.data.kitas.length > 0,
                };
            },

            // map dispatch to props
            (dispatch) => {
                return {
                    loadData(data) {
                        dispatch(
                            loadData(DataProcessor.process(data))
                        );
                    }
                };
            }
        ),

    )(baseSearchComponent);
}