import React from 'react';
import { Marker } from 'react-leaflet';
import { Kita } from '../../state';

interface Icons {
    active: any;
    default: any;
}

export interface Props {
    icons: Icons;
    kita: Kita;
    onClick?(e: MouseEvent);
    onDblClick();
    triggerToggleKitaSelection(kitaId: string);
}

export default ({ icons, kita, onClick, onDblClick }: Props) => {
    return (
        <Marker position={kita.location} title={kita.name} onClick={onClick}
            onDblClick={onDblClick} icon={kita.selected ? icons.active : icons.default} />
    );
};